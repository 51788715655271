<template>
  <div
    class="common-star"
    :class="{ 'large-star': lg, 'clickable-star': clickable }"
  >
    <div
      style="display: flex;align-items: center;"
      @mouseleave="resetCurrentValue"
    >
      <!-- 不要换行格式化, 否则展示会有空格 -->
      <div style="display: flex;align-items: center;position: relative;"> 
        <Icon
          v-for="n in rankData.full"
          :key="n"
          :index="n"
          class="common-star__item common-star__icon"
          :name="startIconName('full')"
          :size="starSize"
          :custom-style="{ verticalAlign: 'none' }"
          @mousemove="setCurrentValue(n)"
          @click="selectValue(n)"
        />
        <div 
          class="popup-tip-content" 
          v-if="isNewStyle && showPopupTips && starPopupId === currPopupId"
        >
          <div>
            {{ popupTips }}
          </div>
          <div class="triangle-up"></div>
        </div>
      </div>
 
      <Icon
        v-for="n in rankData.one"
        :key="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('one')"
        :size="starSize"
        :index="rankData.full + n"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />
      <Icon
        v-for="n in rankData.two"
        :key="rankData.full + n"
        :index="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('two')"
        :size="starSize"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />
      <Icon
        v-for="n in rankData.three"
        :key="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('three')"
        :size="starSize"
        :index="rankData.full + n"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />

      <Icon
        v-for="n in rankData.empty"
        :key="rankData.full + rankData.three + rankData.two + rankData.one + n"
        class="common-star__item common-star__icon"
        :index="
          rankData.full + rankData.three + rankData.two + rankData.one + n
        "
        :name="startIconName('empty')"
        :size="starSize"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + rankData.three + rankData.two + rankData.one + n)"
        @click="selectValue(rankData.full + rankData.three + rankData.two + rankData.one + n)"
      />
    </div>
    <span 
      v-if="showTxt" 
      class="star-txt"
    >{{
      currentValue ? parseFloat(currentValue).toFixed(1) : 0
    }}</span>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  components: { Icon },
  props: {
    modelValue: { default: 0 },
    lg: { default: false },
    clickable: { default: false },
    showTxt: { default: false },
    starsTips: {
      type: Object,
      default: () => ({})
    },
    starPopupId: {
      type: String,
      default: ''
    },
    isNewStyle: {
      type: [Boolean],
      default: false
    },
    language: {
      type: Object,
      default: () => ({})    
    },
    starSize: {
      type: String,
      default: '16px'
    },
  },
  data: function () {
    return {
      // currentValue: 0,
      hoverIndex: -1,
      popupTips: '',
      showPopupTips: false,
      currPopupId: this.randomString(32)
    }
  },
  computed: {
    currentValue() {
      return isNaN(this.modelValue) ? 5 : parseFloat(this.modelValue)
    },
    rankData: function () {
      var full = Math.floor(this.currentValue)
      var one = 0, two = 0, three = 0
      var decimal = Number((this.currentValue + '').split('.')[1])
      if (decimal > 0) {
        if (decimal <= 3) {
          one = 1
        } else if (decimal <= 6) {
          two = 1
        } else if (decimal <= 9) {
          three = 1
        }
      }
      var empty = 5 - (full + one + two + three)
      return {
        full: full,
        one,
        two,
        three,
        empty: empty,
      }
    },
    value: {
      get (){
        return this.modelValue
      },
      set (val){
        this.$emit('input', parseInt(val))
        this.$emit('update:modelValue', parseInt(val))
      }
    }
  },
  methods: {
    startIconName(type) {
      if (type == 'full') {
        return 'sui_icon_star_5_16px_1'
      }
      if (type == 'one') {
        return 'sui_icon_star_2_16px_1'
      }
      if (type == 'two') {
        return 'sui_icon_star_3_16px_1'
      }
      if (type == 'three') {
        return 'sui_icon_star_4_16px_1'
      }
      if (type == 'empty') {
        return 'sui_icon_star_1_16px_1'
      }
    },
    setCurrentValue: function (index) {
      if (!this.clickable) {
        return
      }
      if (index) {
        // this.currentValue = parseInt(index)
        this.hoverIndex = parseInt(index)
      }
    },
    resetCurrentValue: function () {
      if (!this.clickable) {
        return
      }
      // this.currentValue = this.value
      this.hoverIndex = -1
    },
    selectValue: function (index) {
      if (!this.clickable) {
        return
      }
      if(this.isNewStyle){
        this.popupTips = this.starsTips[index]
        this.showPopupTips = false
        setTimeout(() => {
          this.showPopupTips = true
        }, 0)
        this.$emit('set-popup-id', this.currPopupId)
      }
      if (index) {
        this.$emit('input', parseInt(index))
        this.$emit('update:modelValue', parseInt(index))
      }
    },
    randomString(len = 32) { //获得随机组件id
      const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const vl = t.length
      let str = ''
      for (let i = 0; i < len; i++) str += t.charAt(Math.floor(Math.random() * vl))
      return str
    }
  },
  emits: ['input', 'update:modelValue', 'set-popup-id'],
}
</script>
<style lang="less">
.common-star {
    display: inline-block;
    &.large-star {
      .star-txt{
        font-size: 16px;
      }
    }
    &.clickable-star {
        cursor: pointer;
    }
    &__item {
      display: inline-block;
      color: @sui_color_honor !important;  /* stylelint-disable-line declaration-no-important */
      &.common-star__item {
        .margin-r(12px);
      }
    }
    &__icon {
      transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
    }
    .star-txt{
        display: inline-block;
        .margin-l(10px);
    }
    .popup-tip-content{
      display: flex;
      padding: 8px;
      justify-content: center;
      white-space: nowrap;
      font-size: 10px;
      font-weight: 700;
      align-items: center;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
      color: #666666;
      position: absolute;
      bottom: 120%;
      right: 13.5px;
      z-index: -1;
      animation: showPopup 2s linear;
      opacity: 0; 
    }
    .triangle-up {
      position: absolute;
      top: 100%;
      right: 9px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent; /* 左边框透明 */
      border-right: 4px solid transparent; /* 右边框透明 */
      border-top: 5px solid #fff; /* 底边框蓝色 */
    }
}

@keyframes showPopup {
  0%{
    opacity: 1;
    z-index: 1;
  }
  // 20%{
  //   opacity: 1;
  // }
  90%{
    opacity: 1;
    z-index: 1;
  }
  100%{
    opacity: 0;
    z-index: -1
  }
}
</style>
